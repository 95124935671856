// body{
//     margin: unset;
//     background-color: #f3f3f3;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
// }

// *{
//     box-sizing: border-box;
// }

// .container{
//     max-width: 1440px;
//     margin: 0 auto;
//     padding: 0 1em;
// }


/* FONT FACES */

// SUISSEINTL
/* bold */
@font-face {
	font-family: "SuisseIntl";
	src: url("../fonts/SuisseIntl-Bold.otf");
	font-weight: 700;
	font-style: normal;
}
/* semibold */
@font-face {
	font-family: "SuisseIntl";
	src: url("../fonts/SuisseIntl-SemiBold.otf");
	font-weight: 600;
	font-style: normal;
}
/* light */
@font-face {
	font-family: "SuisseIntl";
	src: url("../fonts/SuisseIntl-Light.otf");
	font-weight: 300;
	font-style: normal;
}
/* medium */
@font-face {
	font-family: "SuisseIntl";
	src: url("../fonts/SuisseIntl-Medium.otf");
	font-weight: 500;
	font-style: normal;
}
/* regular */
@font-face {
	font-family: "SuisseIntl";
	src: url("../fonts/SuisseIntl-Regular.otf");
	font-weight: 400;
	font-style: normal;
}

/*  LARISH ALTE  */

/* regular */

@font-face {
	font-family: "Larish";
	src: url("../fonts/LarishAlte-SemiBold.otf");
	font-weight: 600;
	font-style: normal;
}
