.navBar{
    padding: 1em 0;
    background: red;

    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a{
        color:inherit;
        text-decoration: unset;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }

    ul {
        margin: unset;
        padding: unset;
        list-style: none;
    }
}