* {
    /* cursor: none; */
}
@media ( not( ((hover: hover) and (pointer: fine)) ) ) {
  .custom-cursor-ring,
  .custom-cursor-dot,
  .custom-cursor-word,
  .custom-cursor-word span
  {
    display: none;
  }
}

@media (hover: hover) and (pointer: fine) {

.custom-cursor-ring {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border, mix-blend-mode;
    z-index: 999;
    pointer-events: none;
    /* mix-blend-mode: difference; */

    /* filter: 
    sepia(5)
    saturate(100) 
    invert(1)     
    grayscale(1)  
    contrast(9); */

    /* backdrop-filter: grayscale(1); */
  }
  
  .custom-cursor-ring.hovered {
    width: 0px;
    height: 0px;
    border-width: 0px;
    transition-duration:100ms;
    /* border-color: black;
    mix-blend-mode:; */
  }





  .custom-cursor-dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 0px;
    height: 0px;
    background-color: #e5e5e555;
    border-radius: 100%;
    top:0;
    left:0;
    /* transform: translate(-50%, -50%); */
    transform: translate(calc(var(--mouse-x) - 50%), calc(var(--mouse-y) - 50%));

    z-index: 999;
    pointer-events: none;
    /* backdrop-filter:
    sepia(5)
    grayscale(1)
    invert(1)
    contrast(1);
    -webkit-backdrop-filter:
    sepia(5)
    grayscale(1)
    invert(1)
    contrast(1); */
    /* background-blend-mode: difference; */
    /* mix-blend-mode: difference; */


    /* -webkit-transition: width 500ms ease-out 0, height 500ms ease-out 0, left 0 linear 0, top 0 linear 0; */
       /* -moz-transition: width 500ms ease-out 0, height 500ms ease-out 0, left 0 linear 0, top 0 linear 0; */
            /* transition: width 500ms ease-out 0, height 500ms ease-out 0, left 0 linear 0, top 0 linear 0; */
    // transition-property: width, height, transform;
    transition: width 150ms, height 150ms;
    /* transition: width 300ms 0ms, height 300ms 0ms , transform 0ms 0ms; */
    transition-timing-function: ease-out;
    will-change: width, height, transform;
    /* backdrop-filter: blur(2px); */
  }
  
  .custom-cursor-dot.hovered {
    /* display: none; */
    width: 60px;
    height: 60px;
    transition: width 300, height 300ms;
    /* transition-property: width, height, left, top;
    -webkit-transition-duration: 100ms, 100ms, 0, 0;
    transition-duration: 100ms, 100ms, 0, 0; */
    /* background-color: grey;
    mix-blend-mode: screen; */
  }






  .custom-cursor-word {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 0px;
    /* background-color: white; */
    border-radius: 100%;
    top:0;
    left:0;
    /* transform: translate(-50%, -50%); */
    transform: translate(calc(var(--mouse-x) - 50%), calc(var(--mouse-y) - 50%));
    z-index: 999;
    pointer-events: none;
    background-color: transparent;
    /* mix-blend-mode: difference; */
    /* mix-blend-mode: difference; */

    /* transition-property:  width, height;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms; */
    /* transition: width 100ms ease-out, height 100ms ease-out, left 0 linear, top 0 linear; */
    // transition: width 0ms, height 0ms;
    // transition-duration: 0ms, 0ms, 0ms;
    transition-timing-function: ease-out;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  .custom-cursor-word span{
    position: absolute;
    width: 15px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 200ms;
    // transition-delay: 200ms;
    color: black;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    font-family: "Larish";
    // font-family: "SuisseIntl";
  }

  .custom-cursor-word.active {
    // transition: width 0ms, height 0ms;
    // transition-duration: 0ms, 0ms, 130ms, 130ms;
    /* display: none; */
    /* width: 100px;
    height: 100px; */
    /* background-color: grey;
    mix-blend-mode: screen; */
  }

  .custom-cursor-word.active span{
    opacity: 1;
    display: flex;
    /* background-color: grey;
    mix-blend-mode: screen; */
  }
}