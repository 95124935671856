@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 13px;
  @apply text-gray-900;
}

body:after{
  display:none;
  content: url("/noise-web.webp");
}

html.noscroll {
  position: fixed; 
  overflow-y: scroll;
  width: 100%;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 15px;
  }
}

.rich-text-hero a {
  @apply text-blue-600 underline;
}

.rich-text-banner {
  @apply whitespace-pre-line;
  a {
    @apply underline;
  }
}

.title {
  @apply text-4xl leading-snug font-semibold;
}

@media (min-width: 768px) {
  .title {
    @apply text-5xl;
  }
}

.with-arrow:after {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%206a.5.5%200%200%200%200%201V6zM12.854.646a.5.5%200%200%200-.708.708l.708-.708zM18%206.5l.354.354a.5.5%200%200%200%200-.708L18%206.5zm-5.854%205.146a.5.5%200%200%200%20.708.708l-.708-.708zM1%207h16.5V6H1v1zm16.646-.854l-5.5%205.5.708.708%205.5-5.5-.708-.708zm-5.5-4.792l2.75%202.75.708-.708-2.75-2.75-.708.708zm2.75%202.75l2.75%202.75.708-.708-2.75-2.75-.708.708z%22%20fill%3D%22%231264A3%22%2F%3E%3C%2Fsvg%3E);
  content: "";
  width: 19px;
  height: 13px;
  display: inline-block;
  margin-left: 0.5em;
}

// MY CSS


@import "./common.scss";

// compoments

@import "../components/NavBar.scss";

@import "../components/elements/customCursor.scss";


// @import "../components/elements/SimpleSlider.css";

// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

html, body{
  // scroll-behavior: smooth;
  font-family: "SuisseIntl";
}

// body, html {
//   height: 100dvh;
//   width: 100dvw;
//   overscroll-behavior: none;
//   overflow: hidden !important;
// }
// #__next {
//   position: fixed;
//   overflow-x: hidden;
//   overflow-y: scroll;
//   height: 100dvh;
//   width: 100dvw;
//   -webkit-overscroll-behavior: none;
//   overscroll-behavior: none;
//   -webkit-overflow-scrolling: touch;
// }

.larish-font{
  font-family: "Larish";
}

.layout-wrapper{
  position: relative;
  z-index: 1;
}

.background-color{
  background-color: #e5e5e5;
}

// .noise{
//   position: fixed;
//   width: 110vw;
//   height: 110vh;
//   top:0;
//   left:0;
//   transform: translate(-5vw,-5vh);
//   /* background-image: url("./noise.png"); */
//   background-color: #c9c9c9;
//   // background-size: 150px;
//   background-repeat: repeat;
//   mix-blend-mode: overlay;
//   opacity: 0.3;
//   z-index: 3;
//   animation: noiseAnimation .3s infinite;
// }

// @keyframes noiseAnimation {
//   0%{
//     transform: translate(-5vw,-5vh);
//   }
//   12%{
//     transform: translate(-5vw,-10vh);
//   }
//   25%{
//     transform: translate(0vw,-10vh);
//   }
//   38%{
//     transform: translate(0vw,-5vh);
//   }
//   50%{
//     transform: translate(-5vw,-5vh);
//   }
//   62%{
//     transform: translate(-10vw,-5vh);
//   }
//   75%{
//     transform: translate(-10vw,0vh);
//   }
//   88%{
//     transform: translate(-5vw,0vh);
//   }
//   100%{
//     transform: translate(-5vw,-5vh);
//   }
  
// }

// .noise {
//   height: 100vh;
//   pointer-events: none;
// }

// #FD745C
// #b4ccd6
.noise {
  // content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* background: url(https://herdl.com/wp-content/uploads/2020/11/noise-web.webp); */
  // -webkit-animation: 500ms infinite noise;
  // animation: 500ms infinite noise;
  pointer-events: none;
  opacity: 0.2;
  background-color: #fff;
  // background-size: 15%;
  background-size: 200px;
}

.noise.animation{
  -webkit-animation: 500ms infinite noise;
  animation: 500ms infinite noise;
}

@-webkit-keyframes noise {
  0%,
  100% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
}
@keyframes noise {
  0%,
  100% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
}



.divider{
  height: 4px;
  /* UI Properties */
  border-bottom: 1px solid #91919188;
  opacity: 1;

  &.black{
    border-color: #00000033;
  }
}




.swiper-pagination{
}

.swiper-pagination.swiper-pagination-fraction.swiper-pagination-vertical{
  color: white;
  background: "black";
  text-align: left;
  padding-left: 3rem;
  padding-bottom: 2rem;
  font-size: 20px;
  line-height: 1.32;
}


.swiper-wrapper{
  transition-timing-function: cubic-bezier(.45,.37,.19,.76);
}




.button{
  border: 1px solid #FFFFFF;
  border-radius: 1000vw;
  opacity: 1;
  // min-width: 12ch;
  font-size: 14px;
  line-height: 1.2;
  padding: 0.75rem 2rem;
  transition: 0.3s ease-out;
  cursor: pointer;
  position: relative;
  
  &.dark{
    color:white;
    background: #000;
    border-color: #000;

    &.outline-cm{
      color:#000;
      background: transparent;

      border: 1px solid #000 !important;
      --color1-var: #000;
      --color2-var: #000;
      --text-hover: #fff;
      transition: 0.3s ease-out;

      &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color1-var);
        background: linear-gradient(347deg, var(--color1-var) 0%, var(--color2-var) 95%);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-out;
        border-radius: 1000vw;
      }

      &.always-active{

        padding: 0;
        min-width: 50px;

        font-family: "Larish";
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        
        &:before{
          opacity: 1;
        }
      }

      &:hover{
        color:var(--text-hover);
        // background: var(--color1-var);
        // background: linear-gradient(347deg, var(--color1-var) 0%, var(--color2-var) 95%);

        &:before {
          opacity: 1;
        }
      }

      &:focus{
        color:var(--text-hover);
        // background: var(--color1-var);
        // background: linear-gradient(347deg, var(--color1-var) 0%, var(--color2-var) 95%);

        &:before {
          opacity: 1;
        }
      }

      &.active{
        color:var(--text-hover);
        // background: var(--color1-var);
        // background: linear-gradient(347deg, var(--color1-var) 0%, var(--color2-var) 95%);

        &:before {
          opacity: 1;
        }
      }

      

    }
  }

  &:hover{
    color:white;
    background: #000;
  }
}

.event-card:not(.project){
  @media (min-width:768px) {
    border-right: 1px solid #91919188;
  }
  // min-width: 25%;
}

// .event-card.project:nth-child(odd){
//   border:none;
//   @media (min-width:768px) {
//     border-right: 1px solid #91919188;
//   }
//   // min-width: 25%;
// }

.project-card-wrapper{
  .event-card.project{
    border:none;

    @media (min-width:768px) {
      border-right: 1px solid #91919188;

      &.last-project{
        border-right: none;
      }
    }
    // min-width: 25%;
  }

  @media (min-width:768px) {
    &:nth-child(odd){
      .is-filter-section{
        &.event-card.project{
          padding-right: 1.25rem;
        }
      }
    }
    &:nth-child(even){
      .is-filter-section{
        &.event-card.project{
          border-right: none;
          padding-left: 1.25rem;
        }
      }
    }
  }
}

// .event-card-wrapper{
//   .event-card{
//     border:none;

//     @media (min-width:768px) {
//       border-right: 1px solid #91919188;

//       &.last-card{
//         border-right: none;
//       }
//     }
//     // min-width: 25%;
//   }

//   @media (min-width:768px) {
//     &:not(:nth-child(4n)){
//       .is-filter-section{
//         &.event-card{
//           padding-right: 1.25rem;
//         }
//       }
//     }
//     &:nth-child(4n){
//       .is-filter-section{
//         &.event-card{
//           border-right: none;
//           padding-right: 1.25rem;
//           // margin-left: -1.25rem;
//         }
//       }
//     }
//   }
// }

.event-card:not(.project):first-child{
  // margin-left: -1.25rem;
  border-left: none;
}


.event-card:not(.project):last-child{
  // margin-right: -1.25rem;
  border-right: none;
}

.event-card{

  .event-card-image-wrapper img{
    transition: transform 0.5s ease-out;
  }
  &:hover{
    .event-card-image-wrapper img{
      transform: scale(1.1);
    }
  }
}

// .event-card.project:last-child{
//   // margin-right: -1.25rem;
//   border-right: none;
// }


.couto-mixto-markdown{
  p{
    font-size: 18px;
    font-weight: 300;
    &:not(:first-child){
      margin-top: 26px;
    }

    strong{
      font-weight: 400;
    }
  }

  h1,h2,h3,h4{
    font-size: 32px;
    font-weight: 400;
    &:not(:first-child){
      margin-top: 26px;
    }

    strong{
      font-size: 32px;
      font-weight: 500;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 400;
  }

}

.container{
  max-width: unset;
}



.circle {
  position: absolute;
  left: -1vw;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 102vw;
  height: 100%;
  border-radius: 1000vw 1000vw 0 0 / 1000vw 1000vw 0 0;
  // opacity: 0.75;
  // background-color: #d7ff00;
}



.text-left-section-element{
  @media (min-width:768px) {
    max-width: 50%;
  }
}

.word-banner-section{
  .text-left{

    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 2px;
    font-family: "SuisseIntl";
    text-transform: uppercase;

    // @media (min-width:640px) {
    //   font-size: 40px;
    //   font-weight: 300;
    //   letter-spacing: 4px;
    // }

    @media (min-width:768px) {
      font-size: 40px;
      font-weight: 300;
      letter-spacing: 4px;
    }
  }
  .text-right{
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: "Larish";
    text-transform: uppercase;

    @media (min-width:768px) {
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 4px;
    }
  }
}

#transition-element{
  .text-left{

    // display: inline-block;
    font-size: 24px;
    font-weight: 300;
    // letter-spacing: 2px;
    font-family: "Larish";
    // font-family: "SuisseIntl";
    // text-transform: uppercase;
    line-height: 1.2;

    // @media (min-width:640px) {
    //   font-size: 40px;
    //   font-weight: 300;
    //   letter-spacing: 4px;
    // }

    @media (min-width:768px) {
      font-size: 40px;
      font-weight: 300;
      // letter-spacing: 4px;
    }
  }
  .text-right{
    // display: inline-block;
    font-size: 24px;
    font-weight: 600;
    // letter-spacing: 2px;
    font-family: "Larish";
    // text-transform: uppercase;
    line-height: 1.2;

    @media (min-width:768px) {
      font-size: 40px;
      font-weight: 600;
      // letter-spacing: 4px;
    }
  }
}



.hero-banner{
  .title{
    font-family:"Larish";
    font-size:min(70px, 15vw);
    line-height:1.2;
    font-weight:600;
  }
}



.event-info-floater{
  width:100%;

  .event-info-floater-item{
    max-width: 100%;
  }
  @media (min-width:1440px) {
    width: 25%;
    .event-info-floater-item{
      max-width: 263px;
    }
    // letter-spacing: 4px;
  }
}

.event-info-sections{
  width:100%;

  @media (min-width:1440px) {
    width: 75%;
    margin-left: auto;
    // letter-spacing: 4px;
  }

  .word-banner-section{
    @media (min-width:1440px) {
      width:66%;
    }
  }
}



.mySwiper, .mySwiper2{
  height: 100%;
  width: 100%;

  .swiper-slide{
    height:auto;
  }
}

.hero-left-side{
  height: 50svh;
  width: 100%;
  // grid-row:2;
  grid-row:1;

  &.homepage-hero-left-side{
    height: 65svh;
  }

  @media (min-width:768px) {
    height: 100svh;
    width: 100%;
    grid-row:1;
    &.homepage-hero-left-side{
      height: 100svh;
    }
  }
}

.hero-right-side{
  height: 50svh;
  width: 100%;
  // grid-row:1;
  grid-row:2;
  margin:0;
  padding:0;
  &.homepage-hero-left-side{
    height: 35svh;
  }

  @media (min-width:768px) {
    height: 100svh;
    width: 100%;
    grid-row:1;
    &.homepage-hero-left-side{
      height: 100svh;
    }
  }
}


.navbar-links-wrapper{
  width: auto;
  @media (min-width:768px) {
    width: 50%;
  }
  
}



*{
  box-sizing: border-box;
}



.margin-fix-cards{
  @media (min-width:768px) {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
}


#background-wrapper{
  width: 100%;
  height: 100lvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  // opacity: 0.5;
  // @media (min-width:768px) {
  //   // width: 100%;
  //   height: 100vh;
  //   position: fixed;
  //   // top: 0;
  //   // left: 0;
  //   // z-index: 1;
  //   // overflow: hidden;
  // }
}



// HOVER REVEAL


// .hover-reveal {
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0;
// 	left: 0;
// 	pointer-events: none;
// 	opacity: 1;
// }

.hover-reveal__inner,
.hover-reveal__img {
	width: 100%;
	height: 100%;
	position: absolute;
  top:0;
  left:0;
}

.hover-reveal__deco {
	// width: 100%;
  width: 1px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 50%;
  transform: translateX(-50%);
  transform-origin: 50% 100%;
	background-color: #919191;
  // mix-blend-mode: screen;
}

// .hover-reveal__inner {
//   width: 1px;
// 	height: 100%;
// 	position: absolute;
// 	top: 0;
// 	left: 50%;
//   transform: translateX(-50%);
//   transform-origin: 50% 100%;
// 	background-color: #181314;
//   mix-blend-mode: screen;
// }





// NAVBAR LINKS
.link-wrapper{
  .link {
    cursor: pointer;
    // font-size: 18px;
    position: relative;
    // white-space: nowrap;
    // color: var(--color-text);
  }

  .link::before,
  .link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
  }

  .link::before {
    content: '';
    /* show by default */
  }

  .link--elara {
    // font-family: aktiv-grotesk-extended, sans-serif;
    // font-size: 1.375rem;
  }

  .link--elara::before {
    content:"";
    transform-origin: 50% 100%;
    transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
  }



  .link--elara span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  }
  &:hover{
    .link--elara::before {
      content:"";
      transform: translate3d(0, 2px, 0) scale3d(1.0, 2, 1);
      clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
    }

    .link--elara span {
      transform: translate3d(0, 0, 0);
    }
  }

  &.link-wrapper-active{
    .link--elara::before {
      content:"";
      transform: translate3d(0, 2px, 0) scale3d(1.0, 2, 1);
      clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
    }

    .link--elara span {
      transform: translate3d(0, 0, 0);
    }
  }
}





.footer-col-item{
  // border-top: 1px solid #00000033;

  @media (min-width:768px) {
    border-left: 1px solid #00000033;
    // border-top: none;
  }
}

.hero-title-left{
  font-family: "Larish";
  font-size: 28px;
  line-height: 1.2;
  font-weight: 600;
  @media (min-width:768px) {
    font-family: "Larish";
    font-size: 48px;
    line-height: 1.2;
    font-weight: 600;
  }
}

.hero-big-title{
  font-family: "Larish";
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  @media (min-width:768px) {
    font-family: "Larish";
    font-size: 70px;
    line-height: 1.2;
    font-weight: 600;
  }
}

.hero-text-left{
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  max-width: 25ch;
  @media (min-width:768px) {
    // font-family: "Larish";
    font-size: 26px;
    line-height: 1.2;
    font-weight: 400;
    max-width: 25ch;
  }
}

.hero-subtitle-left{
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  max-width: 25ch;
  @media (min-width:768px) {
    // font-family: "Larish";
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
    max-width: 25ch;
  }
}

.hero-title-right{
  font-family: "Larish";
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
  max-width: 16ch;
  @media (min-width:768px) {
    font-family: "Larish";
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    max-width: 16ch;
  }
}



.field-element{
  transition: 0.3s opacity ease-out;
  .divider-wrapper{
    width: 0;
    transition: 0.5s ease-out;
  }

  .field-arrow-wrapper{
    overflow: hidden;
    opacity: 0.2;
    .main-arrow{
      top:0;
      left:0;
      transition: transform 0.4s ease-in-out;
    }


    .hover-arrow{
      top:150%;
      left:-150%;
      transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
    }
  }

  &:hover{
    opacity: 1 !important;
    transition: 0.5s ease-out;
    .divider-wrapper{
      width: 100%;
    }

    .field-arrow-wrapper{
      opacity: 1 !important;
      .main-arrow{
        transform: translate(150%, -150%);
      }


      .hover-arrow{
        top:0;
        left:0;
      }
    }
  }
}




.slideshow-arrow{
  svg{
      path{
          stroke:white;
          stroke-width: 0px;
          transition: 0.2s ease-out
      }
  }
  &:hover{
      cursor: pointer;
      svg{
          path{
              stroke:white;
              stroke-width: 2px;
          }
      }
  }
}